<script>
import { DxSelectBox } from 'devextreme-vue'

const defaultItems = [
  {
    text: 'Today',
    value: 'today',
  },
  {
    text: 'Yesterday',
    value: 'yesterday',
  },
  {
    text: 'Day Before Yesterday',
    value: 'dayBeforeYesterday',
  },
  {
    text: 'Last 3 days',
    value: 'last3days',
  },
  {
    text: 'Last 7 days',
    value: 'last7days',
  },
  {
    text: 'Last 14 days',
    value: 'last14days',
  },
  {
    text: 'Previous Week',
    value: 'previousWeek',
  },
  {
    text: 'This Month',
    value: 'thisMonth',
  },
  {
    text: 'Last Month',
    value: 'lastMonth',
  },
  {
    text: 'Last 30 days',
    value: 'last30days',
  },
  {
    text: 'Last 90 days',
    value: 'last90days',
  },
  {
    text: 'Last 3 months',
    value: 'last3months',
  },
  {
    text: 'Last 6 months',
    value: 'last6months',
  },
  {
    text: 'Last 9 months',
    value: 'last9months',
  },
  {
    text: 'Last 12 months',
    value: 'last12months',
  },
  {
    text: 'Last Year',
    value: 'lastYear',
  },
  {
    text: 'Year to Date',
    value: 'yearToDate',
  },
  {
    text: 'All Time',
    value: 'allTime',
  },
]

export const forecastDateRanges = [
  {
    text: 'Previous Week',
    value: 'previousWeek',
  },
  {
    text: 'Last Month',
    value: 'lastMonth',
  },
  {
    text: 'Last 3 months',
    value: 'last3months',
  },
]

export default {
  name: 'DateRangeSelect',
  components: {
    DxSelectBox,
  },
  props: {
    value: {
      type: String,
      default: null,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    ranges: {
      type: Array,
      default: () => JSON.parse(JSON.stringify(defaultItems)),
    },
  },
  data() {
    return {
      items: [],
      localValue: null,
    }
  },
  created() {
    this.items = JSON.parse(JSON.stringify(this.ranges))
    this.localValue = this.items.find(item => item.value === this.value) || this.items[0]
  },
  methods: {
    onItemClick(e) {
      this.localValue = e.itemData
      this.$emit('value-changed', this.localValue.value)
      this.$emit('input', this.localValue.value)
    },
  },
}
</script>

<template>
  <div class="tw-relative">
    <label
      v-if="localValue"
      class="flying-label tw-z-10"
      for="dateRange"
    >Date Range</label>
    <DxSelectBox
      :value="localValue"
      :items="items"
      :input-attr="{ 'aria-label': 'With Placeholder' }"
      placeholder="Date Range"
      :read-only="readOnly"
      :on-item-click="onItemClick"
      display-expr="text"
      key-expr="value"
      :show-clear-button="false"
    />
  </div>
</template>

<style scoped lang="scss">

</style>
