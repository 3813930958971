<script>
import { DxSelectBox } from 'devextreme-vue'

export default {
  name: 'ForecastAlgorithmSelect',
  components: { DxSelectBox },
  props: {
    value: {
      type: String,
      default: null,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      items: [
        {
          value: 'manual',
          text: 'Manual',
        },
        {
          value: 'prophet',
          text: 'Prophet',
        },
        {
          value: 'sklearn',
          text: 'Sklearn',
        },
        {
          value: 'tensorflow',
          text: 'TensorFlow',
        },
      ],
      localValue: null,
    }
  },
  created() {
    this.localValue = this.items.find(item => item.id === this.value) || this.items[0]
  },
  methods: {
    onItemClick(e) {
      this.localValue = e.itemData
      this.$emit('value-changed', this.localValue.value)
      this.$emit('input', this.localValue.value)
    },
  },
}
</script>

<template>
  <div class="tw-relative">
    <label
      v-if="localValue"
      class="flying-label tw-z-10"
      for="algorithm-select"
    >Algorithm</label>
    <DxSelectBox
      :value="localValue"
      :items="items"
      :input-attr="{ 'aria-label': 'With Placeholder' }"
      :show-clear-button="false"
      placeholder="Algorithm"
      :read-only="readOnly"
      :on-item-click="onItemClick"
      display-expr="text"
      key-expr="value"
    />
  </div>
</template>

<style scoped lang="scss">

</style>
