<script>
import { DxSelectBox } from 'devextreme-vue'
import ShopDatasource from '@/data/shops.datasource'

export default {
  name: 'ShopSelect',
  components: {
    DxSelectBox,
  },
  props: {
    value: {
      type: String,
      default: null,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      datasource: ShopDatasource.getInstance(this.$store),
    }
  },
  computed: {
    localValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
  methods: {
    onItemClick(e) {
      this.$emit('value-changed', e.itemData.id)
      this.$emit('input', e.itemData.id)
    },
  },
}
</script>

<template>
  <div class="tw-relative">
    <label
      v-if="localValue"
      class="flying-label tw-z-10"
      for="shop-select"
    >Shop</label>
    <DxSelectBox
      :value="localValue"
      :data-source="datasource"
      :input-attr="{ 'aria-label': 'With Placeholder' }"
      :show-clear-button="false"
      placeholder="Shop"
      :read-only="readOnly"
      :on-item-click="onItemClick"
      display-expr="owner.name"
      key-expr="id"
    />
  </div>
</template>

<style scoped lang="scss">

</style>
